export const imagesData = [
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/1.png',
    original_url: '/new-design/image-generation/original/1.png',
    user_prompt:
      'A mesmerizing abstract painting that bursts with life, featuring a symphony of vibrant colors that dance and swirl together. The canvas is filled with intricate, interconnected shapes and patterns, each seamlessly blending into the next. The composition embodies creativity and inspiration, with each element harmoniously contributing to the overall masterpiece. This visual feast is designed to inspire the viewer to delve into their own creative journey and explore the boundless realms of their imagination.',
    magic_prompt: '',
    style: 'General',
    aesthetic: '',
    type: '',
    resolution: '1360x1354',
  },
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/2.png',
    original_url: '/new-design/image-generation/original/2.png',
    user_prompt:
      'A captivating, cinematic painting of a quaint town during the twilight hours. The winding road through the town is glistening wet, with a few cars weaving their way through. The bright pink post office stands out, flanked by various shops, including a butcher and a bakery. On the right, residential buildings cast a warm glow, illuminating the misty atmosphere. The background reveals a towering church with a prominent spire, nestled in a valley surrounded by mysterious hills or mountains. The dark fantasy theme adds an enchanting and eerie touch to the overall scene.',
    magic_prompt: '',
    style: 'Realistic',
    aesthetic: '',
    type: '',
    resolution: '690x1108',
  },
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/3.png',
    original_url: '/new-design/image-generation/original/3.png',
    user_prompt:
      'A nice picture of a group of women aged 25-40, who are confident, educated, socially active, and nature lovers. They are fashion-forward but have a consistent personal style. They appreciate relaxed styles and freedom. The women are wearing jeans and casual tops, with some wearing a jacket or cardigan. They are standing in a nature setting, with trees and grass in the background.',
    magic_prompt: '',
    style: 'Realistic',
    aesthetic: '',
    type: '',
    resolution: '1116x1112',
  },
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/4.png',
    original_url: '/new-design/image-generation/original/4.png',
    user_prompt:
      "An impressionism oil painting of a sheep in a chef's hat cutting mushrooms in a cozy kitchen. The sheep is wearing a green chef's hat and a white apron. It is standing on a wooden board. There's a magnet on the refrigerator with the inscription \"championship\". The background contains pots, pans, and other kitchen utensils.",
    magic_prompt: '',
    style: 'General',
    aesthetic: '',
    type: '',
    resolution: '1252x1252',
  },
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/5.png',
    original_url: '/new-design/image-generation/original/5.png',
    user_prompt:
      'A pencil drawing style, A neoclassical portrait of the European Roller painted in soft, harmonious tones of blue and brown. The bird is perched gracefully, with light capturing its soft feathers in a refined, almost noble pose. Gentle highlights and shadows give the bird a sense of depth, complementing its delicate beauty.',
    magic_prompt: '',
    style: 'Realistic',
    aesthetic: '',
    type: '',
    resolution: '1248x1254',
  },
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/6.png',
    original_url: '/new-design/image-generation/original/6.png',
    user_prompt:
      'A stunningly detailed digital artwork depicting a vintage steam locomotive journeying through a rugged mountain landscape. The train, with its polished, dark metal exterior and large, prominent headlight, releases billows of thick, grey smoke that twist and merge with the clouds above, creating a dramatic contrast against the clear, expansive sky. In the background, towering, snow-capped peaks rise majestically, while pine trees and autumnal foliage line the tracks, adding rich green and warm orange hues to the scene. A charming wooden station stands nearby, its architecture rustic and inviting, with warm light glowing from the windows, hinting at a cozy interior. The overall composition captures the nostalgic essence of a bygone era, with meticulous attention to detail in the textures of the train, rails, and natural surroundings. The lighting adds a soft, golden glow, casting long shadows and enhancing the atmospheric beauty of this tranquil, mountainous setting. Golden handwritten Signature at the right',
    magic_prompt: '',
    style: 'Realistic',
    aesthetic: '',
    type: '',
    resolution: '',
  },
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/7.png',
    original_url: '/new-design/image-generation/original/7.png',
    user_prompt:
      'A picturesque scene of a lush, verdant forest lawn adorned with vibrant blooming flowers and well-kept bushes. The sunlight filters through the dense canopy above, casting dappled patterns on the soft grass below. A gentle breeze rustles the leaves and petals, creating a serene and tranquil atmosphere. In the background, the forest continues, with its diverse flora and fauna, inviting visitors to explore its hidden treasures.',
    magic_prompt: '',
    style: 'Realistic',
    aesthetic: '',
    type: '',
    resolution: '998x1250',
  },
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/8.png',
    original_url: '/new-design/image-generation/original/8.png',
    user_prompt:
      "A playful, scruffy-looking Labrador Retriever with its coat dull and slightly muddy, as if it's just returned from a fun, messy outdoor adventure. The Labrador's fur is ruffled, with patches of dirt visible on its paws and around its mouth. Its big, soulful eyes look endearing yet a bit tired, conveying a relatable 'before grooming' moment. The background is simple and neutral, highlighting the dog's rugged appearance and setting the stage for the impressive glow-up it will have after a professional grooming.",
    magic_prompt: '',
    style: 'Realistic',
    aesthetic: '',
    type: '',
    resolution: '710x1244',
  },
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/9.png',
    original_url: '/new-design/image-generation/original/9.png',
    user_prompt:
      'A mesmerizing, hand-signed artwork from El Magnifico, featuring a classic flying saucer hovering low over a barren desert landscape at night. The saucer, illuminated by a ring of bright, circular lights, emits an otherworldly glow that casts a eerie yet captivating light on the sparse vegetation and sandy terrain below. The starry night sky and distant, silhouetted mountains create a sense of isolation, while the glowing saucer hints at a mysterious alien presence. This wide long shot visual masterpiece captures the essence of an unearthly encounter in a remote and desolate corner of Earth.',
    magic_prompt: '',
    style: 'Realistic',
    aesthetic: '',
    type: '',
    resolution: '1252x1226',
  },
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/10.png',
    original_url: '/new-design/image-generation/original/10.png',
    user_prompt:
      "A pencil drawing style, A captivating charcoal sketch captures a serene moment by the water, where a person clad in a hat and carrying a pole with two hanging containers peacefully wades through the shallow water. The soft, dreamy lines create an ethereal atmosphere, with the person's reflection gently mirroring the ripples on the water's surface. A wooden boat, anchored to the right, is softly silhouetted, its reflection seamlessly blending with the tranquil surroundings. The delicate shadings and subtle highlights suggest the calm light of the rising sun, enhancing the serene atmosphere. The minimalistic strokes convey an impression of stillness, as if time itself has paused in this peaceful moment.",
    magic_prompt: '',
    style: 'General',
    aesthetic: '',
    type: '',
    resolution: '832x1254',
  },
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/11.png',
    original_url: '/new-design/image-generation/original/11.png',
    user_prompt:
      'A modern office setting with a sleek design. There are various geometric shapes with vibrant colors scattered throughout the room. These shapes include a large, colorful cube near the window, a small, blue pyramid on a shelf, and a few, smaller, orange and yellow tetrahedrons on the floor. There are also some modern, ergonomic chairs and a few potted plants.',
    magic_prompt: '',
    style: 'Realistic',
    aesthetic: '',
    type: '',
    resolution: '1252x1216',
  },
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/12.png',
    original_url: '/new-design/image-generation/original/12.png',
    user_prompt:
      'An illustration of a black Friday poster with a simple background and clear, bold text. The poster is about telecoms and is in Spanish. It offers a Xiaomi phone and a smartwatch as prizes. The text says "Gratis participar consúltanos", indicating that you can participate for free and consult them.',
    magic_prompt: '',
    style: 'Realistic',
    aesthetic: '',
    type: '',
    resolution: '1248x1252',
  },

  {
    thumbnail_url: '/new-design/image-generation/thumbnail/13.png',
    original_url: '/new-design/image-generation/original/13.png',
    user_prompt:
      'A photo of a 36-year-old nice Caucasian male with a worn-out t-shirt, his hairline slightly receds but he still has a nice full hair in a short haircut, and lots of facial hair. He looks directly at the camera with a shy smile. The background is blurred and contains a few objects.',
    magic_prompt: '',
    style: 'Realistic',
    aesthetic: '',
    type: '',
    resolution: '1252x1252',
  },

  {
    thumbnail_url: '/new-design/image-generation/thumbnail/14.png',
    original_url: '/new-design/image-generation/original/14.png',
    user_prompt:
      'A cinematic shot of an infinity pool suspended on a rocky cliff in a breathtaking mountain landscape. The swimming pool, rectangular in shape and with clean lines, seems to float in space, offering a panoramic view of the valley below, characterized by dense vegetation and imposing rock walls. The cliff, dug to create a sort of natural cave, houses the swimming pool and a relaxation area furnished with loungers and lamps. The architectural structure integrates perfectly with the surrounding environment, creating an atmosphere of luxury and harmony with nature. The swimming pool is surrounded by lush vegetation, with climbing plants that descend along the rocky walls, creating a fascinating contrast between the artificial and natural elements. The sky, partially covered by clouds, adds a touch of mystery to the image, underlining the majesty of the landscape. The image evokes feelings of tranquility, freedom, and connection with nature, inviting the observer to imagine himself immersed in this idyllic place.',
    magic_prompt: '',
    style: 'Realistic',
    aesthetic: '',
    type: '',
    resolution: '1252x1250',
  },
  {
    thumbnail_url: '/new-design/image-generation/thumbnail/15.png',
    original_url: '/new-design/image-generation/original/15.png',
    user_prompt:
      "A captivating image of a vintage wooden barrel labeled 'Idea Barrel' in a rustic setting. A small, silver spoon spirals around inside the barrel, stirring the ideas. Each idea is represented as a colorful, floating, and glowing book. The ambiance is filled with warmth, creativity, and a sense of wonder. The background showcases a cozy and artistic room with shelves full of books and a fireplace, reflecting the heart of intellectual exploration.",
    magic_prompt: '',
    style: 'Realistic',
    aesthetic: '',
    type: '',
    resolution: '700x1250',
  },
]
