// cssUtils.ts

/**
 * Sets a single CSS variable in the :root scope.
 * @param key - The name of the CSS variable (without the '--' prefix).
 * @param value - The value to set for the CSS variable.
 */
export const setCssVariable = (key: string, value: string): void => {
  document.documentElement.style.setProperty(`${key}`, value)
}

/**
 * Sets multiple CSS variables in the :root scope.
 * @param variables - An object containing CSS variables and their values.
 * Example: { 'header-background': '#000', 'header-font': '#fff' }
 */
export const setMultipleCssVariables = (variables: Record<string, string>): void => {
  Object.entries(variables).forEach(([key, value]) => {
    setCssVariable(key, value)
  })
}

/**
 * Gets the current value of a CSS variable.
 * @param key - The name of the CSS variable (without the '--' prefix).
 * @returns The current value of the CSS variable or an empty string if not found.
 */
export const getCssVariable = (key: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(`--${key}`).trim()
}

/**
 * Checks if a CSS variable is defined and has a value.
 * @param key - The name of the CSS variable (without the '--' prefix).
 * @returns True if the CSS variable has a value, otherwise false.
 */
export const isCssVariableDefined = (key: string): boolean => {
  const value = getCssVariable(key)
  return value !== ''
}

/**
 * Resets a CSS variable by removing its value from :root.
 * @param key - The name of the CSS variable (without the '--' prefix).
 */
export const resetCssVariable = (key: string): void => {
  document.documentElement.style.removeProperty(`${key}`)
}
