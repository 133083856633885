import { FC, useEffect, useState } from 'react';
import BulkFileUploadModal from './components/BulkFileUploadModal';
import ImportIcon from '../../icons/workflow/ImportIcon';
import classes from './BulkFileImporterBtn.module.scss';

interface BulkFileImportBtnProps {
  setMappingInputs: any;
  setRowsCount: any;
  templateItem: any;
}

const BulkFileImportBtn: FC<BulkFileImportBtnProps> = ({ setMappingInputs, setRowsCount,templateItem }) => {
  const [isBulkUploadModalVisible, setBulkUploadModalVisible] = useState(false);

  const handleImportClick = () => setBulkUploadModalVisible(true);

  const handleClose = () => {
    console.log('Closing modal');
    setBulkUploadModalVisible(false);
  };

  useEffect(() => {
    console.log('isBulkUploadModalVisible', isBulkUploadModalVisible);
  }, [isBulkUploadModalVisible]);

  return (
    <div className={classes.import}>

      <label className={classes.import__btn} onClick={handleImportClick}>
        <ImportIcon color='#fff'  /> Import Bulk File
      </label>

      <BulkFileUploadModal
        isBulkUploadModalVisible={isBulkUploadModalVisible}
        handleClose={handleClose}
        setMappingInputs={setMappingInputs}
        setRowsCount={setRowsCount}
        templateItem={templateItem}
      />
    </div>
  );
};

export default BulkFileImportBtn;
