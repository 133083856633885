import {Table} from 'react-bootstrap'
import ConfirmationModal from '../../modal-page/ConfirmationModal'
import {Link} from 'react-router-dom'
import EditIcon from '../../icons/homepage/EditIcon'
import classes from '../RecentActivity.module.scss'
import {useAuth} from '../../../../app/modules/auth'
import {useState} from 'react'
import {
  useDeleteActivityMutation,
  useGetActivitiesQuery,
} from '../../../../services/activityServiceApi'
import {GlobalErrorMessagesEnum} from '../../../../types/types'
import {toast} from 'react-toastify'
import DelIcon from '../../icons/homepage/DelIcon'
import {getLastOpenedFormat} from '../../../../utils/getLastOpenedFormat'

const SingleRunComponent = () => {
  const [activityToDelete, setActivityToDelete] = useState<string>('')
  const [deleteActivity] = useDeleteActivityMutation()
  const {currentUser, toggleSidebar} = useAuth()
  const [page, setPage] = useState(1)

  const {
    data: {activities = [], hasMore = false} = {},
    isLoading,
    refetch,
  } = useGetActivitiesQuery({
    user_id: currentUser?.id,
    page,
  })

  const handleCancel = () => {
    setActivityToDelete('')
  }
  const handleConfirm = () => {
    handleDelete(activityToDelete)
    setActivityToDelete('')
  }
  const handleDelete = (uuid: string) => {
    deleteActivity(uuid)
      .unwrap()
      .then(() => {
        toast.success('Activity successfully deleted')
      })
      .catch(() => {
        toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
      })
      .finally(() => {
        refetch()
      })
  }

  const tableRows = activities.map(
    (item: {
      id: number
      header: string
      title: string
      uuid: string
      updatedAt: string
      url: string
      questions: {
        requiredQuestion1: string
      }
    }) => {
      return (
        <tr
          key={item.id}
          style={{
            borderBottom: '1px solid #f1f1f1',
            height: '50px',
          }}
        >
          <td
            style={{
              width: '250px',
            }}
          >
            {item.questions.requiredQuestion1.slice(0, 20)}{' '}
            {item.questions.requiredQuestion1.length > 20 ? '...' : ''}
          </td>
          <td
            style={{
              width: '350px',
              textAlign: 'start',
            }}
          >
            {item.title}
          </td>
          <td>{item.header}</td>
          <td
            style={{
              width: '250px',
              textAlign: 'start',
            }}
          >
            {getLastOpenedFormat(item.updatedAt)}
          </td>
          <td
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '22px',
            }}
          >
            {item.header === 'Image Generation' ? (
              <Link to={`/${item.url}/?uuid=${item.uuid}`} title='Go to your activity'>
                <EditIcon className={classes.svg} />
              </Link>
            ) : (
              <Link
                to={`/${item.header.replace(' ', '-').toLowerCase()}/${item.url}/?uuid=${
                  item.uuid
                }`}
                title='Delete your activity'
              >
                <EditIcon className={classes.svg} />
              </Link>
            )}

            <p
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setActivityToDelete(item.uuid)}
            >
              <DelIcon className={classes.svg} />
            </p>
          </td>
        </tr>
      )
    }
  )
  const handlePagination = (action: string) => {
    if (action === 'next') {
      setPage((prev) => prev + 1)
    } else {
      setPage((prev) => prev - 1)
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className='w-100'>
      <Table
        responsive
        style={{
          borderCollapse: 'collapse',
          borderSpacing: '0',
        }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Workflow</th>
            <th>Category</th>
            <th>Last Opened</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </Table>
      <tfoot
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '22px',
          width: '100%',
        }}
        className='mt-3 mt-md-0'
      >
        {(hasMore || page > 1) && (
          <tr>
            <td colSpan={7}>
              <nav
                className='w-100 bg-light  d-flex justify-content-center p-3'
                style={{
                  borderRadius: '1rem',
                }}
              >
                <ul className='pagination pagination-lg'>
                  <li className='page-item' onClick={() => handlePagination('prev')}>
                    <button className='page-link' disabled={page === 1}>
                      <span aria-hidden='true'>&laquo;</span>
                    </button>
                  </li>
                  <li className='page-item'>
                    <button className='page-link'>{page}</button>
                  </li>
                  <li className='page-item' onClick={() => handlePagination('next')}>
                    <button className='page-link' disabled={!hasMore}>
                      <span aria-hidden='true'>&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
        )}
      </tfoot>
      <ConfirmationModal
        header='Warning'
        message='Are you sure you want to delete this activity?'
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        show={activityToDelete !== '' ? true : false}
      />
    </div>
  )
}

export default SingleRunComponent
