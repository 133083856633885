export const TopUpPlansData = {
  words_and_credits: [
    {
      name: 'Tier 1',
      value: 100000,
      label: '100,000 words + 1000 credits - $50',
      payment_link: 'https://buy.stripe.com/test_5kA6ptdrl1a1d7a5ko',
    },
    {
      name: 'Tier 2',
      value: 200000,
      label: '200,000 words + 2000 credits - $100',
      payment_link: 'https://buy.stripe.com/test_4gwcNR1ID9Gx9UY3ci',
    },
    {
      name: 'Tier 3',
      value: 300000,
      label: '300,000 words + 3000 credits - $150',
      payment_link: 'https://buy.stripe.com/test_6oE1595YTaKBaZ25kr',
    },
    {
      name: 'Tier 4',
      value: 400000,
      label: '400,000 words + 4000 credits - $200',
      payment_link: 'https://buy.stripe.com/test_aEU5lpaf97ypd7aaEM',
    },
    {
      name: 'Tier 5',
      value: 500000,
      label: '500,000 words + 5000 credits - $250',
      payment_link: 'https://buy.stripe.com/test_00gg033QLaKB0ko009',
    },
    {
      name: 'Tier 6',
      value: 600000,
      label: '600,000 words + 6000 credits - $300',
      payment_link: 'https://buy.stripe.com/test_3cs29dfztdWN3wAbIS',
    },
    {
      name: 'Tier 7',
      value: 700000,
      label: '700,000 words + 7000 credits - $350',
      payment_link: 'https://buy.stripe.com/test_aEU159bjdbOFfficMX',
    },
    {
      name: 'Tier 8',
      value: 800000,
      label: '800,000 words + 8000 credits - $400',
      payment_link: 'https://buy.stripe.com/test_aEUeVZcnh6ulebe3co',
    },
    {
      name: 'Tier 9',
      value: 900000,
      label: '900,000 words + 9000 credits - $450',
      payment_link: 'https://buy.stripe.com/test_cN2cNRevp8Ct2sw14h',
    },
    {
      name: 'Tier 10',
      value: 1000000,
      label: '1,000,000 words + 10,000 credits - $500',
      payment_link: 'https://buy.stripe.com/test_00gdRV8715qh0kofZc',
    },
  ],
  images: [
    {name: 'Tier 1 ', value: 100, label: '100 images - $30', payment_link: ''},
    {name: 'Tier 2', value: 200, label: '200 images - $60', payment_link: ''},
    {name: 'Tier 3', value: 300, label: '300 images - $90', payment_link: ''},
    {name: 'Tier 4', value: 400, label: '400 images - $120', payment_link: ''},
    {name: 'Tier 5', value: 500, label: '500 images - $150', payment_link: ''},
    {name: 'Tier 6', value: 600, label: '600 images - $180', payment_link: ''},
    {name: 'Tier 7', value: 700, label: '700 images - $210', payment_link: ''},
    {name: 'Tier 8', value: 800, label: '800 images - $240', payment_link: ''},
    {name: 'Tier 9', value: 900, label: '900 images - $270', payment_link: ''},
    {name: 'Tier 10', value: 1000, label: '1000 images - $300', payment_link: ''},
  ],
}
