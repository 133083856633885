import React from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import classes from '../Resellers.module.scss'
import {
  BsPersonAdd,
  BsPalette,
  BsImage,
  BsBox,
  BsPlusCircle,
  BsListTask,
  BsCreditCard,
  BsPlusSquare,
  BsPersonLinesFill,
  BsPen,
  BsThreeDotsVertical,
} from 'react-icons/bs'

import TopUpIcon from '../../../../icons/white-label/TopupIcon'
import ListIcon from '../../../../icons/white-label/ListIcon'
import InformationIcon from '../../../../icons/white-label/InformationIcon'
import LogoIcon from '../../../../icons/white-label/LogoIcon'
import ColorIcon from '../../../../icons/white-label/ColorIcon'
import EditIcon from '../../../../icons/images/image-generation-form/EditIcon'
import CreateIcon from '../../../../icons/white-label/CreateIcon'
import ImagesIcon from '../../../../icons/navbar/ImagesIcon'
import HistoryIcon from '../../../../icons/white-label/HistoryIcon'

interface SidebarProps {
  activeCell: string
  setActiveCell: (title: string) => void
}

const sidebarConfig = [
  {
    section: 'Account',
    items: [
      {key: 'INFORMATION', label: 'Information', icon: InformationIcon},
      {key: 'LOGO', label: 'Logo', icon: ImagesIcon},
      {key: 'COLOR', label: 'Color', icon: ColorIcon},
    ],
  },
  {
    section: 'Package',
    items: [
      {key: 'PACKAGE_CREATE', label: 'Create', icon: CreateIcon},
      {key: 'PACKAGE_LIST', label: 'List', icon: ListIcon},
    ],
  },
  {
    section: 'Payment',
    items: [
      {key: 'TOP_UP', label: 'TopUp', icon: TopUpIcon},
      {key: 'TOP_UP_HISTORY', label: 'History', icon: HistoryIcon},
    ],
  },
]

const Sidebar: React.FC<SidebarProps> = ({activeCell, setActiveCell}) => {
  const navigate = useNavigate()

  const handleClick = (key: string) => {
    const formattedKey = key.toLowerCase().replace(/_/g, '-')
    setActiveCell(key)
    navigate(`#${formattedKey}`)
  }

  return (
    <div
      className='col-md-2 px-0'
      style={{
        minHeight: '100vh',
        backgroundColor: 'white',
        borderRight: '1px solid #edf2f7',
        padding: '1rem 0',
        fontSize: 14,
      }}
    >
      <nav className='nav flex-column'>
        {sidebarConfig.map((section) => (
          <div key={section.section}>
            <div
              style={{
                color: 'var(--primary-font-color)',
                fontWeight: 600,
                padding: '1.25rem 1.5rem 0.5rem',
                letterSpacing: '0.05em',
              }}
              className={classes.white_label__sub_title}
            >
              {section.section}
            </div>
            {section.items.map((item) => {
              const IconComponent = item.icon

              return (
                <>
                  <Link
                    to='#'
                    key={item.key}
                    className={`nav-link ${activeCell === item.key ? 'active' : ''} ${
                      classes.white_label__sub_title
                    }`}
                    style={{
                      color:
                        activeCell === item.key
                          ? 'var(--primary-color)'
                          : 'var(--secondary-font-color)',
                      padding: '0.5rem 1.5rem',
                      transition: 'all 0.2s',
                      fontWeight: activeCell === item.key ? 600 : 500,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      handleClick(item.key)
                    }}
                  >
                    <span>
                      <IconComponent height='20px' width='20px' className='me-3' />

                      {item.label}
                    </span>
                  </Link>
                </>
              )
            })}
          </div>
        ))}
      </nav>
    </div>
  )
}

export default Sidebar
