import {FC, useEffect} from 'react'
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'
import Layout from '../components/Layout'
import HomePage from '../components/homepage/HomePage'
import MarketingPage from '../components/marketing-page/MarketingPage'
import SalesPage from '../components/sales-page/SalesPage'
import HRPage from '../components/hr-page/HRPage'
import FinancePage from '../components/finance-page/FinancePage'
import ChatPage from '../components/chat-page/ChatPage'
import ImagesPageV1 from '../components/images-page/ImagesPage'
import ImagesPageV2 from '../components/images-page-1/ImagesPage'
import ModalPage from '../components/modal-page/ModalPage'
import SalesTemplatePage from '../components/sales-page/template-page/SalesTemplatePage'
import MarketingTemplatePage from '../components/marketing-page/template-page/MarketingTemplatePage'
import HRTemplatePage from '../components/hr-page/template-page/HRTemplatePage'
import FinanceTemplatePage from '../components/finance-page/template-page/FinanceTemplatePage'
import ProfilePage from '../components/profile-page/ProfilePage'
import PlanPage from '../components/plan-page/PlanPage'
import {useAuth} from '../../app/modules/auth'
import {Login} from '../components/auth-page/Login'
import {Registration} from '../components/auth-page/Registration'
import {ForgotPassword} from '../components/auth-page/ForgotPassword'
import App from '../components/App'
import {NewPassword} from '../components/auth-page/NewPassword'
import MarketingBulkRunPage from '../components/marketing-page/bulk-run-page/MarketingBulkRunPage'
import SalesBulkRunPage from '../components/sales-page/bulk-run-page/SalesBulkRunPage'
import HRBulkRunPage from '../components/hr-page/bulk-run-page/HRBulkRunPage'
import RecentActivityPage from '../components/recent-activity-page/RecentActivityPage'
import authorizedBrowserExtension from '../../utils/browserExtension/authorizedBrowserExtension'
import {queryParameters} from '../../app/modules/auth/core/_requests'
import ProjectPage from '../components/project-page/ProjectPage'
import RealEstatePage from '../components/real-estate-page/RealEstatePage'
import RealEstateTemplatePage from '../components/real-estate-page/template-page/RealEstateTemplatePage'
import RealEstateBulkRunPage from '../components/real-estate-page/bulk-run-page/RealEstateBulkRunPage'
import ApiDocs from '../components/docs/ApiDocs'
import DemoTour from '../../_metronic/layout/components/DemoTour/DemoTour'
import DemoAppTour from '../components/demo-app-tour/DemoAppTour'
import {isBetaUser, isKlevereBetaUser, isPaidUser, isSuperAdmin} from '../../utils/userRoles'

const MyRoutes: FC = () => {
  const {currentUser} = useAuth()
  const redirectUri = queryParameters.get('redirect_uri') || null

  useEffect(() => {
    if (!currentUser) return
    const chromeExtensionPrefix = 'chrome-extension://'
    if (redirectUri && redirectUri.startsWith(chromeExtensionPrefix)) {
      authorizedBrowserExtension(currentUser, redirectUri)
    }
  }, [currentUser])

  return (
    <Router>
      <Routes>
        <Route element={<App />}>
          {currentUser &&
          currentUser.is_survey_completed &&
          !currentUser.first_login_at &&
          !isKlevereBetaUser(currentUser?.email) ? (
            <Route path='/' element={<DemoAppTour />}>
              <Route path='*' element={<Navigate replace to='/auth' />} />
            </Route>
          ) : currentUser ? (
            <Route path='/' element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path='marketing'>
                <Route index element={<MarketingPage />} />
                <Route path=':templateUrl'>
                  <Route index element={<MarketingTemplatePage />} />
                  <Route path='bulk-run' element={<MarketingBulkRunPage />} />
                </Route>
              </Route>
              <Route path='sales'>
                <Route index element={<SalesPage />} />
                <Route path=':templateUrl'>
                  <Route index element={<SalesTemplatePage />} />
                  <Route path='bulk-run' element={<SalesBulkRunPage />} />
                </Route>
              </Route>
              <Route path='human-resource'>
                <Route index element={<HRPage />} />
                <Route path=':templateUrl'>
                  <Route index element={<HRTemplatePage />} />
                  <Route path='bulk-run' element={<HRBulkRunPage />} />
                </Route>
              </Route>

              <Route path='finance'>
                <Route index element={<FinancePage />} />
                <Route path=':templateUrl'>
                  <Route index element={<FinanceTemplatePage />} />
                  <Route path='bulk-run' element={<HRBulkRunPage />} />
                </Route>
              </Route>

              <Route path='real-estate'>
                <Route index element={<RealEstatePage />} />
                <Route path=':templateUrl'>
                  <Route index element={<RealEstateTemplatePage />} />
                  <Route path='bulk-run' element={<RealEstateBulkRunPage />} />
                </Route>
              </Route>

              {/* <Route path='finance'>
              <Route index element={<FinancePage />} />
              <Route path='workflows/:templateUrl' element={<FinanceWorkflowPage />} />
              <Route path=':templateUrl' element={<FinanceTemplatePage />} />
            </Route> */}

              <Route path='chat' element={<ChatPage />} />

              <Route path='images' element={<ImagesPageV2 />} />
              {/* {isSuperAdmin(currentUser.email) ? (
                <Route path='images' element={<ImagesPageV2 />} />
              ) : (
                <Route path='images' element={<ImagesPageV1 />} />
              )} */}

              <Route path='modal' element={<ModalPage />} />
              <Route path='profile' element={<ProfilePage />} />
              <Route path='upgrade-plan' element={<PlanPage />} />
              <Route path='recent_activity' element={<RecentActivityPage />} />

              {isPaidUser(currentUser) && <Route path='project' element={<ProjectPage />} />}

              <Route path='*' element={<Navigate replace to='/' />} />
            </Route>
          ) : (
            <>
              <Route path='auth'>
                <Route index element={<Login />} />
                <Route path='registration' element={<Registration />} />
                <Route path='forgot-password' element={<ForgotPassword />} />
                <Route path='restore-password' element={<NewPassword />} />
              </Route>
              <Route path='api/docs' element={<ApiDocs />} />
              <Route path='*' element={<Navigate replace to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </Router>
  )
}

export default MyRoutes
