import {useState, type FC} from 'react'
import classes from './DraftsGenerate.module.scss'
import SelectArrowIcon from '../../icons/modalform/SelectArrowIcon'
import RightArrowIcon from '../../icons/chat/RightArrowIcon'
import CopyIcon from '../../icons/chat/CopyIcon'
import RefreshIcon from '../../icons/chat/RefreshIcon'
import ReactLoading from 'react-loading'
import ImagesContainer from '../image-generation/ImagesContainer'
import {toast} from 'react-toastify'

interface DraftsGenerateProps {
  currentAnswer: string
  images?: string[]
  handleAnswerToEdit: any
  isLoadingText?: boolean
  isLoadingImg?: boolean
  disabledButtons: boolean
  setTriggerRegenerateText?: Function
  setTriggerRegenerateImg?: Function
  message?: string
  setMessage?: Function
  isVisibleMessage?: boolean
  handleMessageVisiability?: Function
}

const DraftsGenerate: FC<DraftsGenerateProps> = ({
  currentAnswer,
  images = [],
  handleAnswerToEdit,
  isLoadingText = false,
  isLoadingImg = false,
  disabledButtons = false,
  setTriggerRegenerateText = () => {},
  setTriggerRegenerateImg = () => {},
  message = '',
  setMessage = () => {},
  isVisibleMessage = false,
  handleMessageVisiability = () => {},
}) => {
  const maxTextLength = 80

  const slicedCurrentAnswer = currentAnswer.split(' ').slice(0, maxTextLength)
  const shortedText = slicedCurrentAnswer.join(' ')

  const [isVisibleDrafts, setIsVisibleDrafts] = useState<boolean>(true)
  const [isExpandedText, setIsExpandedText] = useState<boolean>(false)

  const handleVisibleDrafts = () => {
    setIsVisibleDrafts((prev) => !prev)
  }

  const handleRegenerate = (isImage = false) => {
    isImage ? setTriggerRegenerateImg(Date.now()) : setTriggerRegenerateText(Date.now())
  }

  const handleExpandText = () => {
    setIsExpandedText((prev) => !prev)
  }
  const handleCopyAnswer = async (dataToCopy: any) => {
    try {
      if (dataToCopy === '...') {
        toast.warning('No content to copy!')
        return
      }
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(dataToCopy)
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = dataToCopy
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
      }
      toast.success('Content copied successfully')
    } catch (error) {
      console.error('Unable to copy to clipboard', error)
    }
  }
  const resultsBlock = currentAnswer.split('\n').map((item: any, index: number) => {
    return (
      <p key={index}>
        {item}{' '}
        {index === currentAnswer.split('\n').length - 1 &&
          slicedCurrentAnswer.length >= maxTextLength && (
            <button
              className={classes.drafts__content__container__generation__show}
              onClick={handleExpandText}
            >
              Show less
            </button>
          )}
      </p>
    )
  })

  const shortResultsBlock = shortedText.split('\n').map((item: any, index: number) => {
    return (
      <p key={index}>
        {item}{' '}
        {index === shortedText.split('\n').length - 1 && (
          <button
            className={classes.drafts__content__container__generation__show}
            onClick={handleExpandText}
          >
            Show more
          </button>
        )}
      </p>
    )
  })

  return (
    <div className={classes.drafts}>
      <button className={classes.drafts__showDraftsBtn} onClick={handleVisibleDrafts}>
        <SelectArrowIcon
          className={`${
            isVisibleDrafts
              ? classes.drafts__showDraftsBtn__svgActive
              : classes.drafts__showDraftsBtn__svg
          }`}
        />
        Drafts
      </button>

      {isVisibleDrafts && (
        <div className={classes.drafts__content}>
          <div className={classes.drafts__content__container}>
            <div className={classes.drafts__content__container__panel}>
              <h3 className={classes.drafts__content__container__panel__title}>Text</h3>
              <div className={classes.drafts__content__container__panel__btns}>
                <button title='Move to Editor' onClick={handleAnswerToEdit}>
                  <RightArrowIcon
                    className={classes.drafts__content__container__panel__btns__svg}
                  />
                </button>
                <button title='Copy to clipboard' onClick={() => handleCopyAnswer(currentAnswer)}>
                  <CopyIcon className={classes.drafts__content__container__panel__btns__svg} />
                </button>
                <button
                  className={`${isLoadingText ? classes.animated : undefined}`}
                  title='Regenerate'
                  disabled={disabledButtons}
                  onClick={() => handleRegenerate()}
                >
                  <RefreshIcon className={classes.drafts__content__container__panel__btns__svg} />
                </button>
              </div>
            </div>
            <div className={classes.drafts__content__container__generation}>
              {isLoadingText ? (
                <div className={classes.drafts__content__container__generation__loaderBlock}>
                  <ReactLoading type='spinningBubbles' color='#2468ff' height='6rem' width='6rem' />
                </div>
              ) : slicedCurrentAnswer.length >= maxTextLength && !isExpandedText ? (
                shortResultsBlock
              ) : (
                resultsBlock
              )}
            </div>
          </div>

          {(images.length > 0 || isLoadingImg || isVisibleMessage) && (
            <div className={classes.drafts__content__container}>
              <div className={classes.drafts__content__container__panel}>
                <h3 className={classes.drafts__content__container__panel__title}>Images</h3>
                <p
                  className={
                    !message.toLowerCase().includes('error')
                      ? classes.drafts__content__container__panel__successMessage
                      : classes.drafts__content__container__panel__errorMessage
                  }
                  style={{display: isVisibleMessage ? 'flex' : 'none'}}
                >
                  {message}
                </p>
                <div className={classes.drafts__content__container__panel__btns}>
                  <button
                    className={`${isLoadingImg ? classes.animated : undefined}`}
                    title='Regenerate'
                    disabled={disabledButtons}
                    onClick={() => handleRegenerate(true)}
                  >
                    <RefreshIcon className={classes.drafts__content__container__panel__btns__svg} />
                  </button>
                </div>
              </div>
              <div className={classes.drafts__content__container__generation}>
                <ImagesContainer
                  images={images}
                  isLoading={isLoadingImg}
                  setMessage={setMessage}
                  handleMessageVisiability={handleMessageVisiability}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DraftsGenerate
