import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {upload} from '../../../../../../utils/fileUploader'
import {useResourceUpdateOrCreateMutation} from '../../../../../../services/settingServiceApi'
import {toast} from 'react-toastify'
import ReactLoading from 'react-loading'
import {Spinner} from 'react-bootstrap'
import {CustomTooltip} from '../../../../universal-components/tooltip/Tooltip'
import classes from './../Resellers.module.scss'

interface Logo {
  id: string
  title: string
  errorKey: string
  dimensions: {width: number; height: number}
  acceptedFormats: string
  logoPlaceholder: string
  tooltip: string
}

const logoData: Logo[] = [
  {
    id: 'large_logo',
    title: 'Large Logo',
    errorKey: 'logo',
    dimensions: {width: 262, height: 84},
    acceptedFormats: '.png',
    logoPlaceholder: 'https://placehold.co/262x84',
    tooltip: 'Your main logo. It should be at least 262x84 pixels in size.',
  },
  {
    id: 'smaller_logo',
    title: 'Smaller Logo',
    errorKey: 'logo',
    dimensions: {width: 60, height: 60},
    acceptedFormats: '.png',
    logoPlaceholder: 'https://placehold.co/60x60',
    tooltip: 'Your smaller logo. It should be at least 60x60 pixels in size.',
  },
  {
    id: 'favicon',
    title: 'Favicon',
    errorKey: 'favicon',
    dimensions: {width: 16, height: 16},
    acceptedFormats: '.ico',
    logoPlaceholder: 'https://placehold.co/16x16',
    tooltip: 'Your favicon. It should be at least 16x16 pixels in size.',
  },
]

const validationSchema = Yup.object().shape({
  large_logo: Yup.mixed()
    .required('Interface logo is required')
    .test('fileSize', 'File size is too large', (value) => {
      if (value && value instanceof File) {
        return value.size <= 2000000
      }
      return false
    })
    .test('fileType', 'Unsupported File Format', (value) => {
      if (value && value instanceof File) {
        return ['image/png'].includes(value.type)
      }
      return false
    }),

  smaller_logo: Yup.mixed()
    .required('Icon logo is required')
    .test('fileSize', 'File size is too large', (value) => {
      if (value && value instanceof File) {
        return value.size <= 2000000
      }
      return false
    })
    .test('fileType', 'Unsupported File Format', (value) => {
      if (value && value instanceof File) {
        return ['image/png'].includes(value.type)
      }
      return false
    }),

  favicon: Yup.mixed()
    .required('Favicon is required')
    .test('fileSize', 'File size is too large', (value) => {
      if (value && value instanceof File) {
        return value.size <= 2000000
      }
      return false
    })
    .test('fileType', 'Unsupported File Format', (value) => {
      if (value && value instanceof File) {
        return ['image/x-icon'].includes(value.type)
      }
      return false
    }),
})

interface LogoComponentProps {
  data?: any
  refetch: () => void
}

const LogoComponent: React.FC<LogoComponentProps> = ({data, refetch}) => {
  const [submit] = useResourceUpdateOrCreateMutation()
  const [logos, setLogos] = useState<{[key: string]: File | null}>({
    large_logo: null,
    smaller_logo: null,
    favicon: null,
  })

  const [errors, setErrors] = useState<{[key: string]: string}>({})
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState('')

  const validateImageDimensions = (file: File, dimensions: {width: number; height: number}) => {
    return new Promise<void>((resolve, reject) => {
      const img = new Image()
      img.src = URL.createObjectURL(file)
      img.onload = () => {
        if (img.width !== dimensions.width || img.height !== dimensions.height) {
          reject(new Error(`Must be ${dimensions.width}x${dimensions.height} pixels.`))
        } else {
          resolve()
        }
      }
      img.onerror = () => reject(new Error('Image could not be loaded.'))
    })
  }

  const handleFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    dimensions: {width: number; height: number}
  ) => {
    const file = e.target.files?.[0]

    if (!file) {
      setLogos((prev) => ({...prev, [id]: null}))
      setErrors((prev) => ({...prev, [id]: 'File is required'}))
      return
    }

    setLogos((prev) => ({...prev, [id]: file}))
    setErrors((prev) => ({...prev, [id]: ''}))

    try {
      setIsLoading(true)
      setType(id)
      await validationSchema.validateAt(id, {[id]: file})
      await validateImageDimensions(file, dimensions)

      const res = await upload(file)
      setErrors((prev) => ({...prev, [id]: ''}))

      await submit({type: id, url: res.data.location})
      toast.success('Uploaded successfully')
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred.'
      setErrors((prev) => ({...prev, [id]: errorMessage}))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='mb-5'>
      <div className='row'>
        {logoData.map(({id, title, dimensions, acceptedFormats, logoPlaceholder, tooltip}) => {
          const logoUrl = data?.[id] || logoPlaceholder

          return (
            <div className={`${classes.imageContainer} col-md-4`} key={id}>
              <p>
                {title} {dimensions.width}x{dimensions.height} ({acceptedFormats})
                <CustomTooltip tooltipText={tooltip} tooltipAnchor='formName' />
              </p>

              <label
                htmlFor={id}
                className='w-100 mb-0 mt-2'
                style={{
                  border: '1px solid rgb(222, 226, 230)',
                  borderRadius: 10,
                  padding: 40,
                  textAlign: 'center',
                  backgroundImage: ` ${isLoading && type === id ? 'none' : `url('${logoUrl}')`}  `,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  cursor: 'pointer',
                  transition: '0.3s',
                  position: 'relative',
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.borderColor = '#adb5bd'
                  e.currentTarget.style.backgroundColor = '#f8f9fa'
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.borderColor = '#dee2e6'
                  e.currentTarget.style.backgroundColor = '#fff'
                }}
              >
                {isLoading && type === id && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 10,
                    }}
                  >
                    <ReactLoading type='spin' color='#007bff' height={40} width={40} />
                  </div>
                )}

                <input
                  type='file'
                  id={id}
                  accept={acceptedFormats}
                  style={{display: 'none'}}
                  onChange={(e) => handleFileChange(e, id, dimensions)}
                />
              </label>

              {errors[id] && <div className='text-danger'>{errors[id]}</div>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LogoComponent
