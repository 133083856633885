import {useState, type FC, useEffect} from 'react'
import classes from './CombinedGeneration.module.scss'
import CopyIcon from '../../icons/chat/CopyIcon'
import MyEditor from '../my-editor/MyEditor'
import DraftsGenerate from '../drafts-generate/DraftsGenerate'
import {AnswerToEdit} from '../../../../types/types'
import RunIcon from '../../icons/workflow/RunIcon'
import CombinedForm from '../combined-form/CombinedForm'
import UniversalHiddenScreen from '../hidden-screen/UniversalHiddenScreen'
import TwitterPreview from '../preview-social/TwitterPreview'
import InstagramPreview from '../preview-social/InstagramPreview'
import FacebookPreview from '../preview-social/FacebookPreview'
import LinkedinPreview from '../preview-social/LinkedinPreview'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {LimitNotification} from '../limit-notification/LimitNotification'
import {toast} from 'react-toastify'
import {Button} from 'react-bootstrap'
import {GiHamburgerMenu} from 'react-icons/gi'
import {isFreemiumUser, isKlevereBetaUser, isPaidUser} from '../../../../utils/userRoles'
import CombinedWorkflowRealEstate from '../combined-form/CombinedWorkflowRealEstate'
import LeadFinderCombinedForm from '../combined-form/LeadFinderCombinedForm'
import LeadFinderBulkRun from '../bulk-run/LeadFinderBulkRun'

interface LeadFinderCombinedGenerationProps {
  templateItem: any
}

const LeadFinderCombinedGeneration: FC<LeadFinderCombinedGenerationProps> = ({templateItem}) => {
  const standartColor = '#ffff'
  const {toggleSidebar, currentUser} = useAuth()
  const [currentAnswer, setCurrentAnswer] = useState('...')
  const [answerToEdit, setAnswerToEdit] = useState<AnswerToEdit>({
    id: 0,
    text: '',
  })
  const [previewTemplate, setPreviewTemplate] = useState('')
  const [images, setImages] = useState<string[]>([])
  const [triggerCopyEditor, setTriggerCopyEditor] = useState<number | string>('')
  const [triggerRegenerateText, setTriggerRegenerateText] = useState<number | string>('')
  const [triggerRegenerateImg, setTriggerRegenerateImg] = useState<number | string>('')
  const [disabledButtons, setDisabledButtons] = useState(false)
  const [isLoadingImg, setIsLoadingImg] = useState(false)
  const [isLoadingText, setIsLoadingText] = useState(false)
  const [visibleBackground, setVisibleBackground] = useState(false)
  const [message, setMessage] = useState<string>('')
  const [isVisibleMessage, setIsVisibleMessage] = useState(false)
  const [error, setError] = useState('')

  const contentBlock = {
    Instagram: <InstagramPreview images={images} text={currentAnswer} />,
    Facebook: <FacebookPreview images={images} text={currentAnswer} />,
    LinkedIn: <LinkedinPreview images={images} text={currentAnswer} />,
    Twitter: <TwitterPreview images={images} text={currentAnswer} />,
  }

  const handleMessageVisiability = () => {
    setIsVisibleMessage(true)

    setTimeout(() => {
      setIsVisibleMessage(false)
    }, 2000)
  }

  const handleBackgroundVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const isRestrictedUrl = () => {
    const url = window.location.href
    const pattern = /\/finance\/financial-statement-summary$/
    return pattern.test(url)
  }

  const handleTriggerCopyEditor = () => {
    setTriggerCopyEditor(Date.now())
  }

  const handleAnswerToEdit = () => {
    setAnswerToEdit({id: Date.now(), text: currentAnswer})
  }
  const handleCopyAnswer = async (dataToCopy: any) => {
    try {
      if (dataToCopy === '...') {
        toast.warning('No content to copy!')
        return
      }
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(dataToCopy)
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = dataToCopy
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
      }
      toast.success('Content copied successfully')
      handleTriggerCopyEditor()
    } catch (error) {
      console.error('Unable to copy to clipboard', error)
    }
  }
  useEffect(() => {
    if (!isLoadingImg && !isLoadingText) {
      setDisabledButtons(false)
    }
  }, [isLoadingImg, isLoadingText])

  return (
    <div
      className={`universalPage ${classes.combined} mb-5 mb-md-0`}
      style={{padding: '2.29rem 2.29rem 2.29rem calc(2% + 2.29rem)'}}
    >
      {error.trim() !== '' ? <LimitNotification errorMessage={error} /> : null}
      <UniversalHiddenScreen
        visible={visibleBackground}
        handleVisibility={handleBackgroundVisibility}
        contentBlock={contentBlock[previewTemplate as keyof typeof contentBlock]}
      />
      <div className='container-fluid'>
        <div className='row'>
          <LeadFinderBulkRun title='' templateItem={templateItem} />
        </div>
      </div>
    </div>
  )
}

export default LeadFinderCombinedGeneration
