import {Dropdown, Table} from 'react-bootstrap'
import {useGetPlanQuery, useGetPlansQuery} from '../../../../../../services/settingServiceApi'
import {BsPencil, BsThreeDotsVertical} from 'react-icons/bs'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import ReferralMemberClasses from '../../../ReferralMembersArea.module.scss'
import {useState} from 'react'

interface SidebarProps {
  setActiveCell: (title: string) => void
}

export const PackageListComponent: React.FC<SidebarProps> = ({setActiveCell}) => {
  const [page, setPage] = useState(1)
  const {data: plans, isLoading} = useGetPlansQuery({page: page, limit: 10})
  const navigate = useNavigate()

  const handlePagination = (action: string) => {
    if (action === 'next') {
      setPage((prev) => prev + 1)
    } else {
      setPage((prev) => prev - 1)
    }
  }

  const handleEdit = (publicId: string) => {
    setActiveCell('PACKAGE_CREATE')
    navigate(`#plan?public_id=${publicId}`)
  }

  const tableRows = () => {
    if (plans?.data?.length) {
      return plans.data.map((plan: any) => (
        <tr key={plan.id}>
          <td>{plan.name}</td>
          <td>{(plan.amount).toFixed(2)}</td>
          <td>{plan.currency?.toUpperCase()}</td>
          <td>{plan.interval}</td>
          <td>{plan.words}</td>
          <td>{plan.images}</td>
          <td>{plan.credits}</td>
          <td>{plan.users}</td>
          <td>{plan.active ? 'Active' : 'Inactive'}</td>
          <td style={{display: 'flex', justifyContent: 'center'}}>
            <Dropdown>
              <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                <BsThreeDotsVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    handleEdit(plan.public_id)
                  }}
                  disabled={plan.id <= 8}
                >
                  <BsPencil /> Edit
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      ))
    } else {
      return (
        <tr>
          <td style={{textAlign: 'center'}} colSpan={11}>
            No plans available
          </td>
        </tr>
      )
    }
  }

  return (
    <div className={ReferralMemberClasses.referral_members}>
      <div className='w-100'>
        <Table
          responsive
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '0',
          }}
        >
          <thead>
            <tr>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Name</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Amount</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Currency</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Interval</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Words</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Images</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Credits</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Max Users</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Status</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Action</th>
            </tr>
          </thead>
          <tbody>{tableRows()}</tbody>

          <tfoot
            style={{
              width: '100%',
            }}
            className='mt-3 mt-md-0'
          >
            <tr>
              <td colSpan={9}>
                <nav className='w-100 d-flex justify-content-center align-items-center mt-3'>
                  <ul className='pagination pagination-lg'>
                    <li className='page-item' onClick={() => handlePagination('prev')}>
                      <button className='page-link' disabled={page === 1}>
                        <span aria-hidden='true'>&laquo;</span>
                      </button>
                    </li>
                    <li className='page-item'>
                      <button className='page-link'>{page}</button>
                    </li>

                    <li className='page-item' onClick={() => handlePagination('next')}>
                      <button className='page-link' disabled={plans && !plans.hasMore}>
                        <span aria-hidden='true'>&raquo;</span>
                      </button>
                    </li>
                  </ul>
                </nav>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  )
}
