import {useEffect, useState, type FC} from 'react'
import {
  Button,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Spinner,
  Tab,
  Tabs,
} from 'react-bootstrap'
import {useAllocateUserLimitMutation} from '../../../../services/companyServiceApi'
import {useCreateBrandVoiceMutation} from '../../../../services/brandVoiceServiceApi'
import classes from '../MembersArea.module.scss'
import PersonaClasses from '../Persona.module.scss'

import {toast} from 'react-toastify'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import {GlobalErrorMessagesEnum} from '../../../../types/types'
import {CustomTooltip} from '../../universal-components/tooltip/Tooltip'

const BrandVoiceTrainingModal = ({
  setShowTrainingModal,
  showTrainingModal,
  refetch,
  data,
}: {
  showTrainingModal: any
  setShowTrainingModal: any
  refetch: any
  data: any
}) => {
  const [submitBrandVoice, {isLoading}] = useCreateBrandVoiceMutation()
  const [type, setType] = useState('FILE')
  const [dataSource, setDataSource] = useState('FILE')
  const {currentUser} = useAuth()
  const [errors, setErrors] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [content, setContent] = useState('')
  const [name, setName] = useState('')
  const [handlePostType, setHandlePostType] = useState('NEW_POST')
  const [brandVoiceId, setBrandVoiceId] = useState(null)

  useEffect(() => {
    if (data) {
      setName(data.name)
      setContent(data.personality_traits)
      setBrandVoiceId(data.id)
      setHandlePostType('OLD_POST')
    }
  }, [data])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const data = {
      id: brandVoiceId,
      name: name,
      content: content,
    }

    submitBrandVoice(data)
      .unwrap()
      .then((data) => {
        refetch()
        toast.success(`Brand voice "${data?.name && data.name.slice(0, 10)}" created successfully`)
        // setContent(data.personality_traits)
        // setHandlePostType('OLD_POST')
        handleClose()
      })
      .catch((error) => {
        console.log(error)
        if (error.status === 400) {
          setErrors(error.data.message)
          toast.error(error.data.message[0])
          return
        }
        toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
      })
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setType(event.target.value)
  }

  const handleInputChange = (event: any) => {
    event.target.value = event.target.value.replace(/\D+/g, '')
  }

  const handleClose = () => {
    setName('')
    setContent('')
    setShowTrainingModal(false)
    setBrandVoiceId(null)
  }

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name

    if (e.target.files !== null && e.target.files.length > 0) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)

      setIsUploading(true)
      axios
        .post(`${process.env.REACT_APP_API_URL}/aws/upload`, formData)
        .then((res: any) => {
          if (res.data.success === true) {
            const fileLink = res.data.location
            setDataSource(fileLink)
          }
        })
        .catch((e: any) => {
          if (e.response.data.message) {
            toast.error('Please provide a valid file')
          }
        })
        .finally(() => setIsUploading(false))
    }
  }

  return (
    <>
      <Modal show={showTrainingModal} onHide={handleClose} centered backdrop='static'>
        <Modal.Header
          style={{
            border: 'none',
          }}
          closeButton
        >
          <Modal.Title>{!data ? 'Add Brand Voice' : 'Edit Brand Voice'} </Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <InputGroup className='my-4 mt-2 d-flex justify-content-between align-items-center'>
              <label htmlFor='words'>
                Brand Voice Name <sup className='text-danger'>*</sup>
                <CustomTooltip
                  tooltipText='Enter the name of your brand voice'
                  tooltipAnchor='formName'
                />
              </label>
              <input
                name='name'
                type='text'
                className='p-3 w-100'
                placeholder='Name of your brand voice'
                style={{
                  borderRadius: '5px',
                  border: '1px solid #ced4da',
                  outline: 'none',
                }}
                minLength={3}
                maxLength={100}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </InputGroup>

            <label htmlFor='words'>
              {data ? 'Your Brand Voice Content' : 'Content for Analysis'}{' '}
              <sup className='text-danger'>*</sup>
              <CustomTooltip
                tooltipText={
                  data
                    ? 'Provide content that best represents your brand voice.'
                    : 'Enter content to analyze and determine your brand voice.'
                }
                tooltipAnchor='formContent'
              />
            </label>
            <Form.Control
              name='content'
              as='textarea'
              placeholder='Share content that reflects your unique brand voice. Keep it between 500-2000 characters for the best impact.'
              style={{height: '100px'}}
              minLength={500}
              maxLength={1000}
              value={content}
              required
              onChange={(e) => setContent(e.target.value)}
            />

            {/* <div className='col-5'>
              Data source type
              <div className=''>
                <select
                  className='form-select mb-2'
                  data-control='select4'
                  data-placeholder='Select an option'
                  onChange={handleSelectChange}
                >
                  {['FILE', 'LINK'].map((type, index) => (
                    <option key={index} value={type}>
                      {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {type === 'FILE' ? (
              <label className={PersonaClasses.__fileInput}>
                + Upload File
                <input
                  type='file'
                  required={true}
                  style={{
                    width: '100%',
                  }}
                  onChange={uploadFile}
                />
              </label>
            ) : null}

            {type === 'LINK' ? (
              <InputGroup className='my-4 mt-2 d-flex justify-content-between align-items-center'>
                <label htmlFor='words'>Enter your link</label>
                <input
                  name='source_url'
                  type='text'
                  placeholder='https://example.com/your-article'
                  className='p-3 w-100'
                  style={{
                    borderRadius: '5px',
                    border: '1px solid #ced4da',
                    outline: 'none',
                  }}
                  required
                />
              </InputGroup>
            ) : null} */}
          </Modal.Body>
          <Modal.Footer
            style={{
              padding: '1rem',
              borderTop: 'none',
              gap: '16px',
            }}
          >
            <div className={classes.maping__modalSubmitOuter}>
              <button
                type='button'
                className={classes.maping__modalCloseButton}
                onClick={handleClose}
              >
                Close
              </button>
            </div>
            <div className={classes.maping__modalSubmitOuter}>
              <button
                type='submit'
                className='btn btn-primary px-4 h-100 py-2'
                disabled={isLoading || isUploading}
              >
                {isUploading || isLoading ? (
                  <Spinner animation='border' role='status' size='sm'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default BrandVoiceTrainingModal
