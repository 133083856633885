import {useEffect, type FC} from 'react'
import {AuthInit} from '../../app/modules/auth'
import {Outlet} from 'react-router-dom'
import {IntercomProvider} from 'react-use-intercom'

import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {setCssVariable, setMultipleCssVariables} from '../../utils/setting/cssUtils'
import {useGetSettingQuery} from '../../services/settingServiceApi'

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID
  ? process.env.REACT_APP_INTERCOM_APP_ID
  : ''

interface AppProps {}

const App: FC<AppProps> = () => {
  const {data: response, isLoading: getApiLoading} = useGetSettingQuery(undefined)

  useEffect(() => {
    if (!getApiLoading && response) {
      // Assuming data.colors['--header-background'] exists
      // const headerBackgroundColor = data.colors['--header-background'] || '#1e90ff'; // Fallback color
      // setCssVariable('--header-background', data.colors['--header-background'] || '#1e90ff');

      if (!response.success) return
      if (response.theme_type === 'GLOBAL') return

      if (response.data.ui_configs.colors) {
        setMultipleCssVariables(response.data.ui_configs.colors)
      }
    }
  }, [getApiLoading, response])

  return (
    <AuthInit>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <Outlet />
        <ToastContainer
          position='bottom-right'
          autoClose={2500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
      </IntercomProvider>
    </AuthInit>
  )
}

export default App
