import React, {FC, useState} from 'react'
import classes from './BulkRunTableHeader.module.scss'
import {Form} from 'react-bootstrap'

interface BulkRunTableHeaderProps {
  templateItem: any
}

const BulkRunTableHeader: FC<BulkRunTableHeaderProps> = ({templateItem}) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = (e: any) => {
    const isCheckedNow = e.target.checked
    setIsChecked(isCheckedNow)

    const checkboxes = document.querySelectorAll('td input[type="checkbox"]')

    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = isCheckedNow
    })
  }

  const {requiredQuestions, additionalQuestions, optionalSelect, imgGenerate, formType} =
    templateItem

  const requiredQuestionsHeaders = requiredQuestions.map((item: any, index: number) => (
    <th key={index} className={classes.thead__questionHeader}>
      {item.title} <span className={classes.required}>*</span>
    </th>
  ))

  const additionalQuestionsHeaders = additionalQuestions.map((item: any, index: number) => (
    <th key={index} className={classes.thead__questionHeader}>
      {item.title}
    </th>
  ))

  const optionalSelectHeaders = optionalSelect.map((item: any, index: number) => (
    <th key={index} className={classes.thead__selectHeader} style={{width: '11%'}}>
      {item.title}
    </th>
  ))

  return (
    <thead className={classes.thead}>
      <tr>
        <th className={'align-middle'} style={{width: '20px'}} id='table-checkbox-header'>
          <Form.Check>
            <Form.Check.Input
              isValid
              type={'checkbox'}
              onChange={handleCheckboxChange}
              checked={isChecked}
            />
          </Form.Check>
        </th>

        <th style={{width: '5%'}}>Run</th>
        {requiredQuestionsHeaders}
        {optionalSelectHeaders}
        <th className={classes.thead__selectHeader} style={{width: '10%'}}>
          Output language
        </th>
        {formType === 'social' && (
          <>
            <th className={classes.thead__selectHeader}>Number of variations</th>
            <th className={classes.thead__selectHeader}>
              Media selector<span className={classes.required}>*</span>
            </th>
          </>
        )}
        {/* {imgGenerate && <th className={classes.thead__checkboxHeader}>Include images</th>} */}
        {additionalQuestionsHeaders}
        <th style={{width: '20%'}}>Result</th>
        <th style={{width: '8%'}}>Actions</th>
        {/* {imgGenerate && <th className={classes.thead__imagesHeader}>Images</th>} */}
      </tr>
    </thead>
  )
}

export default BulkRunTableHeader
