import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import * as authHelper from '../app/modules/auth/core/AuthHelpers'
import {refreshBaseQuery} from './refreshBaseQuery'

const API_URL = process.env.REACT_APP_API_URL

export const bulkRunServiceApi = createApi({
  reducerPath: 'bulkRunServiceApi',
  baseQuery: refreshBaseQuery,
  tagTypes: ['BulkRun'],
  endpoints: (builder) => ({
    findAll: builder.query({
      query: ({page}) => ({
        url: `/bulk-run?page=${page}`,
        method: 'GET',
      }),
    }),
    findOne: builder.query({
      query: (uuid) => ({
        url: `/bulk-run/${uuid}`,
        method: 'GET',
      }),
    }),
    create: builder.mutation({
      query: (data) => ({
        url: '/bulk-run',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BulkRun'],
    }),
    delete: builder.mutation({
      query: (uuid) => ({
        url: `/bulk-run/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BulkRun'],
    }),
  }),
})
export const {useCreateMutation, useFindAllQuery, useFindOneQuery, useDeleteMutation} = bulkRunServiceApi
