import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const ChevronDownIcon: FC<Props> = ({
  className,
  width = '24',
  height = '24',
  color = '#27272A',
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill={color}
      viewBox='0 0 20 20'
      style={{transform: 'rotate(180deg)'}}
      className={className}
    >
      <g>
        <path
          fill={color}
          d='M4.147 12.354a.5.5 0 01-.001-.707L9.61 6.163a.55.55 0 01.779 0l5.465 5.484a.5.5 0 01-.708.706L10 7.188l-5.146 5.165a.5.5 0 01-.707.001z'
        ></path>
      </g>
    </svg>
  )
}

export default ChevronDownIcon
