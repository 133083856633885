import {useState, FC} from 'react'
import classes from './Resellers.module.scss'

import Sidebar from './components/Sidebar'
import ColorComponent from './components/ColorComponent'
import LogoComponent from './components/LogoComponent'
import InformationComponent from './components/InformationComponent'
import {useGetSettingQuery} from '../../../../../services/settingServiceApi'
import PlanComponent from './components/PackageComponent'
import PaymentComponent from './components/TopUpComponent'
import TopUpComponent from './components/TopUpComponent'
import {TopUpHistoryComponent} from './components/TopUpHistoryComponent'
import {PackageListComponent} from './components/PackageListComponent'

const Resellers: FC = () => {
  const DEFAULT_CELL = 'INFORMATION'
  const [activeCell, setActiveCell] = useState<string>(DEFAULT_CELL)
  const {data: response, isLoading, refetch} = useGetSettingQuery(undefined)

  const componentInfo: Record<
    string,
    {title: string; description: string; component: JSX.Element}
  > = {
    INFORMATION: {
      title: 'Information',
      description: 'Manage information for your app',
      component: <InformationComponent data={response?.data} refetch={refetch} />,
    },
    LOGO: {
      title: 'Logo',
      description: 'Manage logo & favicon for your app',
      component: <LogoComponent data={response?.data} refetch={refetch} />,
    },
    COLOR: {
      title: 'Color Settings',
      description: 'Manage color scheme for your app',
      component: <ColorComponent />,
    },
    PACKAGE_CREATE: {
      title: 'Package Create',
      description: 'Manage your subscription plan',
      component: <PlanComponent />,
    },
    PACKAGE_LIST: {
      title: 'Package List',
      description: 'List of your subscription plan',
      component: <PackageListComponent setActiveCell={setActiveCell} />,
    },
    TOP_UP: {
      title: 'TopUp',
      description: 'Purchase words, credits, and images',
      component: <TopUpComponent />,
    },
    TOP_UP_HISTORY: {
      title: 'TopUp History',
      description: 'List of your TopUp history',
      component: <TopUpHistoryComponent setActiveCell={setActiveCell} />,
    },
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '3rem',
      }}
      className={classes.referral}
    >
      <div className='container-fluid'>
        <div className='row'>
          <Sidebar activeCell={activeCell} setActiveCell={setActiveCell} />
          <div className='col-md-10 p-4 gap-5'>
            <h3 className={`mb-2 ${classes.white_label__title} `}>
              {componentInfo[activeCell]?.title}
            </h3>
            <p className={`mb-5 ${classes.white_label__sub_title} `}>
              {componentInfo[activeCell]?.description}
            </p>

            {componentInfo[activeCell].component}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resellers
