import {useState, type FC} from 'react'
import classes from './MapingModal.module.scss'
import {languages} from './data/languages'
import {tones} from './data/tones'
import CSVIcon from './Icons/CSVIcon'

interface MapingModalProps {
  CSVTableHeaders: string[]
  bulkTableHeaders: string[]
  setColumnsOrder: Function
  setTriggerFormSubmited: Function
  handleVisibility?: Function
  setDescription?: Function
  description?: string
}

const MapingModal: FC<MapingModalProps> = ({
  CSVTableHeaders = [],
  bulkTableHeaders = [],
  setColumnsOrder = () => {},
  setTriggerFormSubmited = () => {},
  handleVisibility = () => {},
  setDescription = () => {},
  description = '',
}) => {
  const [inputs, setInputs] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])


  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = +e.target.value
    const columnIndex = +e.target.name
    const newInputs = [...inputs]
    newInputs[columnIndex] = value

    setInputs(newInputs)
  }

  const cancelModal = () => {
    handleVisibility()
  }

  const isOneDescriptionForAll = window.location.href.includes(
    'description-comparer-custom'
  );

  const handleForm = (e: React.FormEvent) => {
    e.preventDefault()

    // if(isOneDescriptionForAll) {
    //   inputs[1] = 1
    // }

    setColumnsOrder(inputs)
    setTriggerFormSubmited(Date.now)
    handleVisibility()
  }

  const optionsBlock = CSVTableHeaders.map((item: string, index: number) => {
    return (
      <option key={index} value={index}>
        {item}
      </option>
    )
  })

  const formRows = bulkTableHeaders.map(
    (item: string, index: number) =>
      !['Run', 'Result', 'Actions'].includes(item) && (
        <label key={index} className={classes.maping__form__row}>
          <h3 className={classes.maping__form__row__title}>{item}</h3>
          {item === 'Tone' ? (
            <select name={index.toString()} value={inputs[index]} onChange={handleChange}>
              {tones.map((tone, toneIndex) => (
                <option key={toneIndex} value={toneIndex}>
                  {tone}
                </option>
              ))}
            </select>
          ) : item === 'Output language' ? (
            <select name={index.toString()} value={inputs[index]} onChange={handleChange}>
              {languages.map((language, langIndex) => (
                <option key={langIndex} value={langIndex}>
                  {language}
                </option>
              ))}
            </select>
          ) : (
            <select name={index.toString()} value={inputs[index]} onChange={handleChange}>
              {optionsBlock}
            </select>
          )}
        </label>
      )
  )

 

  return (
    <div className={classes.maping}>
      <h1 className={classes.maping__title}>
        <b>Map CSV Columns</b> &nbsp;
        <span style={{marginLeft: '5px'}}>
          <CSVIcon />
        </span>
      </h1>

      <form className={classes.maping__form} onSubmit={handleForm}>

      {/* {isOneDescriptionForAll && (
        <textarea name="job description" placeholder="Enter job description" rows={4} value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
      )}
       */}

        {formRows}
        <div style={{display: 'flex', justifyContent: 'end', gap: '1rem'}}>
          <div className={classes.maping__modalSubmitOuter}>
            <button
              onClick={cancelModal}
              type='button'
              className={classes.maping__modalCloseButton}
            >
              Close
            </button>
          </div>
          <div className={classes.maping__modalSubmitOuter}>
            <button type='submit' className={classes.maping__modalSubmitButton}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default MapingModal
