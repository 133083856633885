import {Dispatch, SetStateAction, useState} from 'react'
import {toast} from 'react-toastify'
import {Modal, Spinner, Form} from 'react-bootstrap'
import {Formik, Field, ErrorMessage} from 'formik'
import * as yup from 'yup'
import classes from '../../../../profile-page/MembersArea.module.scss'
import {useUpdateNoteMutation} from '../../../../../../services/settingServiceApi'
import {GlobalErrorMessagesEnum} from '../../../../../../types/types'

const AddNoteModal = ({
  isModalVisible,
  setModalVisibility,
  existingData,
  refetch,
}: {
  isModalVisible: boolean
  setModalVisibility: Dispatch<SetStateAction<boolean>>
  existingData?: any
  refetch: any
}) => {
  const [updateNote, {isLoading}] = useUpdateNoteMutation()
  const [note, setNote] = useState('')

  const handleCloseModal = () => {
    setModalVisibility(false)
  }

  const validationSchema = yup.object().shape({
    note: yup
      .string()
      .required('Note is required.')
      .min(3, 'Note must be at least 3 characters.')
      .max(200, 'Note must not exceed 200 characters.'),
  })

  const handleSubmit = (values: {note: string}, {setSubmitting}: any) => {
    updateNote({public_id: existingData?.public_id, note: values.note})
      .unwrap()
      .then((res) => {
        toast.success(existingData ? 'Updated successfully' : 'Added successfully')
        setSubmitting(false)
        handleCloseModal()
      })
      .catch((e) => {
        toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
      })
      .finally(() => {
        setSubmitting(false)
        refetch()
      })
  }

  return (
    <Modal show={isModalVisible} onHide={handleCloseModal} centered backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{existingData?.note ? 'Update Note' : 'Add Note'}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          note: existingData?.note || '', // Pre-fill with existing note if available
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({handleSubmit, isSubmitting}) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group className='mb-4' controlId='noteTextArea'>
                <Form.Label>
                  Note <sup className='text-danger'>*</sup>
                </Form.Label>
                {/* If existingData exists, allow editing; else, provide a new input */}
                <Field
                    as='textarea'
                    name='note'
                    rows={4}
                    className='form-control'
                    placeholder='Edit note'
                  />
                <ErrorMessage name='note' component='div' className='text-danger mt-2' />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <button
                type='button'
                onClick={handleCloseModal}
                className={classes.maping__modalCloseButton}
              >
                Close
              </button>
              <button type='submit' className='btn btn-primary px-4 py-2' disabled={isSubmitting}>
                {isSubmitting ? (
                  <Spinner animation='border' role='status' size='sm'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                ) : (
                  'Submit'
                )}
              </button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddNoteModal
