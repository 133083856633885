import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const ClipBoardIcon: FC<Props> = ({className, width = '24', height = '24', color = '#27272A'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill={color}
      className={className}
    >
      <path
        d='M13.0495 19.4203C12.5095 19.4203 11.9395 19.3703 11.3195 19.2703L6.6295 18.5303C5.1495 18.3003 4.0095 17.8903 3.1595 17.2903C0.969499 15.7703 1.0995 13.1603 1.3795 11.3303L2.1195 6.6403C2.7995 2.34029 5.0195 0.730295 9.3195 1.4003L14.0095 2.1403C16.2195 2.4903 19.1895 3.3803 19.3795 7.1103C19.4195 7.7503 19.3795 8.4803 19.2395 9.3403L18.5095 14.0303C17.9295 17.7003 16.2195 19.4203 13.0495 19.4203ZM7.5295 2.7503C5.1495 2.7503 4.0695 3.9603 3.5995 6.8803L2.8595 11.5703C2.3995 14.5203 3.2395 15.5203 4.0195 16.0703C4.6795 16.5403 5.6095 16.8603 6.8595 17.0503L11.5495 17.7903C15.0395 18.3403 16.4795 17.2903 17.0295 13.7903L17.7595 9.1003C17.8795 8.3503 17.9195 7.7203 17.8795 7.1903V7.18029C17.7695 5.0803 16.5795 4.0503 13.7695 3.6103L9.0895 2.8803C8.5095 2.7903 7.9995 2.7503 7.5295 2.7503Z'
        fill={color}
      />
      <path
        fill={color}
        d='M14.6812 22.749C13.8312 22.749 12.8712 22.569 11.7612 22.199L7.25123 20.699C4.68123 19.849 3.29123 18.629 2.87123 16.849C2.80123 16.549 2.92123 16.229 3.18123 16.059C3.44123 15.889 3.78123 15.889 4.03123 16.069C4.69123 16.539 5.61123 16.859 6.86123 17.049L11.5512 17.789C15.0412 18.339 16.4812 17.289 17.0312 13.789L17.7612 9.09897C17.8812 8.34897 17.9212 7.71897 17.8812 7.18897C17.8712 6.92897 18.0012 6.66897 18.2312 6.51897C18.4612 6.36897 18.7512 6.35897 18.9912 6.48897C21.6712 7.91897 22.3812 10.209 21.2212 13.709L19.7212 18.219C19.0112 20.339 18.0812 21.639 16.7812 22.289C16.1512 22.599 15.4612 22.749 14.6812 22.749ZM5.73123 18.359C6.21123 18.679 6.85123 18.989 7.72123 19.279L12.2312 20.779C13.9512 21.349 15.1812 21.399 16.1012 20.949C17.0212 20.489 17.7212 19.469 18.2912 17.749L19.7912 13.239C20.6112 10.759 20.2912 9.43897 19.3412 8.54897C19.3212 8.79897 19.2812 9.05897 19.2412 9.33897L18.5112 14.029C17.8312 18.329 15.6112 19.939 11.3112 19.279L6.62123 18.539C6.31123 18.479 6.01123 18.419 5.73123 18.359Z'
      />
      <path
        d='M8.24 9.71828C6.87 9.71828 5.75 8.59828 5.75 7.22828C5.75 5.85828 6.87 4.73828 8.24 4.73828C9.61 4.73828 10.73 5.85828 10.73 7.22828C10.73 8.59828 9.61 9.71828 8.24 9.71828ZM8.24 6.24828C7.7 6.24828 7.25 6.68828 7.25 7.23828C7.25 7.78828 7.69 8.22828 8.24 8.22828C8.78 8.22828 9.23 7.78828 9.23 7.23828C9.23 6.68828 8.78 6.24828 8.24 6.24828Z'
        fill={color}
      />
    </svg>
  )
}

export default ClipBoardIcon
