import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {refreshBaseQuery} from './refreshBaseQuery'

const API_URL = process.env.REACT_APP_API_URL

export const imageGenerationService = createApi({
  reducerPath: 'generateImageV2',
  baseQuery: refreshBaseQuery,
  endpoints: (builder) => ({
    generateImage: builder.mutation({
      query: (data) => ({
        url: `${API_URL}/gpt/image-generation-v2`,
        method: 'POST',
        body: data,
      }),
    }),
    getGeneratedImages: builder.mutation({
      query: ({page}) => ({
        url: `${API_URL}/gpt/image-generation-v2?page=${page}`,
      }),
    }),
  }),
})

export const {useGenerateImageMutation, useGetGeneratedImagesMutation} = imageGenerationService
