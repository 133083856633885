import React, {FC} from 'react'
import ExportIcon from '../../icons/workflow/ExportIcon'
import classes from './ExportCSVBtn.module.scss'
import axios from 'axios'
import { toast } from 'react-toastify'

interface ExportCSVBtnProps {
  myRef: React.RefObject<HTMLDivElement>
}

const ExportCSVBtn: FC<ExportCSVBtnProps> = ({myRef}) => {
  const isProfessionalEmailFinderWorkflow = window.location.pathname.includes(
    'professional-email-finder'
  )

  const createCSV = () => {
    const theadRow = myRef?.current?.querySelector('tr')?.querySelectorAll('th')

    const theadCells = () => {
      if (theadRow)
        return (
          [].slice
            .call(theadRow)
            .map((cell: HTMLTableCellElement, index) => {
              // Specific for Professional Email Finder
              if (index !== 0 && isProfessionalEmailFinderWorkflow) {
                return `"${cell.textContent}"`
              }

              // Skip first (index 0), second (index 1), and last column
              if (index > 1 && index < theadRow.length - 1) {
                return `"${cell.textContent}"`
              }
            })
            .filter(Boolean)
            .join(',') + '\n'
        )
      return ''
    }
    const thead = theadCells()

    // Get all rows, including those with checkboxes
    let rows = myRef?.current?.querySelectorAll('tr:not(:has(th))')

    // Filter rows based on checkbox selection
    const filteredRows = [].slice.call(rows || []).filter((row: HTMLTableRowElement) => {
      const checkbox = row.querySelector('input[type="checkbox"]') as HTMLInputElement
      return checkbox && checkbox.checked
    })

    const allRows = [].slice.call(rows || [])

    const result = (filteredRows.length > 0 ? filteredRows : allRows)
      .map(function (row: HTMLTableRowElement) {
        const cells = row.querySelectorAll('td')
        return [].slice
          .call(cells)
          .map((cell: HTMLTableCellElement, index) => {
            // Specific for Professional Email Finder
            if (index !== 0 && isProfessionalEmailFinderWorkflow) {
              return `"${cell.textContent}"`
            }

            // Skip first (index 0), second (index 1), and last column
            if (index > 1 && index < cells.length - 1) {
              const textArea = cell.querySelector('textarea')
              const select = cell.querySelector('select')
              const div = cell.querySelector('[class*=text]')
              const span = cell.querySelector('span')

              return `"${
                span?.textContent ? span.textContent.replace(/"/g, "'").replace(/\n/g, '') : ''
              } ${textArea?.value ? textArea.value.replace(/"/g, "'").replace(/\n/g, '') : ''}${
                select?.value ? select.value.replace(/"/g, "'").replace(/\n/g, '') : ''
              }${div?.textContent ? div.textContent.replace(/"/g, "'").replace(/\n/g, '') : ''}"`
            }
          })
          .filter(Boolean)
          .join(',')
      })
      .join('\n')

    const table = `${thead}${result}`
    return table
  }

  const exportCSV = () => {
    // Check if we're in the CV Screening/ Linkedin Screening workflow
    const isCVScreeningWorkflow = window.location.pathname.includes('screen-custom')
    const isLinkedinScreeningWorkflow = window.location.pathname.includes('description-comparer-custom')

    let rows = myRef?.current?.querySelectorAll('tr:not(:has(th))')

    const checkboxIds = [].slice.call(rows || []).filter((row: HTMLTableRowElement) => {
      const checkbox = row.querySelector('input[type="checkbox"]:checked') as HTMLInputElement;
      return checkbox;
    }).map((row: HTMLTableRowElement) => {
      const checkbox = row.querySelector('input[type="checkbox"]:checked') as HTMLInputElement;
      const checkboxId = checkbox?.getAttribute('data-checkbox-id');
      return checkboxId;
    });
    
    if (isCVScreeningWorkflow || isLinkedinScreeningWorkflow) {
      const uuid = window.location.search.split('?uuid=')[1]

      if(!uuid) {
        toast.warning('Before export, Please run it first!')
        return
      }

      const params = checkboxIds && checkboxIds.length > 0 
        ? { exportable_ids: checkboxIds.join(',') } 
        : {};
    
      const endpoint = isCVScreeningWorkflow
        ? `${process.env.REACT_APP_API_URL}/gpt/export-csv/cv-screening/${uuid}/`
        : `${process.env.REACT_APP_API_URL}/gpt/export-csv/linkedin-screening/${uuid}/`;

      const fileName = isCVScreeningWorkflow
        ? `cv-screening-${uuid}.csv`
        : `linkedin-screening-${uuid}.csv`;

      axios
        .get(endpoint, {
          responseType: 'blob',
          params
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.error('Error downloading CSV:', error)
        })
    } else {
      const table = createCSV()

      if (table.trim() === '') {
        alert('No data to export')
        return
      }

      const link = document.createElement('a')
      link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(table)}`)
      link.setAttribute('download', `bulk_data_${Date.now()}.csv`)
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <button className={classes.export__btn} onClick={() => exportCSV()}>
      <ExportIcon /> Export CSV
    </button>
  )
}

export default ExportCSVBtn
