import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {refreshBaseQuery} from './refreshBaseQuery'

const API_URL = process.env.REACT_APP_API_URL

export const balanceServiceApi = createApi({
  reducerPath: 'balanceServiceApi',
  baseQuery: refreshBaseQuery,
  tagTypes: ['Balance'],
  endpoints: (builder) => ({
    getBalance: builder.query({
      query: ({userId}) => ({
        url: `balance`,
      }),
      providesTags: ['Balance'],
    }),
  }),
})
export const {useGetBalanceQuery} = balanceServiceApi
