// apiSlice.js
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {refreshBaseQuery} from './refreshBaseQuery'

const API_URL = process.env.REACT_APP_API_URL

export const settingServiceApi = createApi({
  reducerPath: 'site-setting',
  baseQuery: refreshBaseQuery,
  tagTypes: ['SiteSetting'],
  endpoints: (builder) => ({
    getSetting: builder.query({
      query: (publicId) => ({
        url: `/site-setting/`,
      }),
      providesTags: ['SiteSetting'],
      //   transformResponse: (response: any) => response.data,
    }),
    resourceUpdateOrCreate: builder.mutation({
      query: ({type, url}) => ({
        url: '/site-setting/logos',
        method: 'PATCH',
        body: {type, url},
      }),
      invalidatesTags: ['SiteSetting'],
    }),
    informationUpdateOrCreate: builder.mutation({
      query: ({name, title, description}) => ({
        url: '/site-setting/information',
        method: 'PATCH',
        body: {name, title, description},
      }),
      invalidatesTags: ['SiteSetting'],
    }),
    planUpdateOrCreate: builder.mutation({
      query: (data) => ({
        url: '/site-setting/plan',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['SiteSetting'],
    }),
    updateColor: builder.mutation({
      query: (data) => ({
        url: '/site-setting/color',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['SiteSetting'],
    }),
    getColor: builder.query({
      query: ({publicId}) => ({
        url: `/site-setting/color`,
      }),
      providesTags: ['SiteSetting'],
    }),
    getPlan: builder.query({
      query: ({publicId}) => ({
        url: `/site-setting/plan/${publicId}`,
      }),
      providesTags: ['SiteSetting'],
      //   transformResponse: (response: any) => response.data,
    }),
    getPlans: builder.query({
      query: ({page,limit,status}) => ({
        url: `/site-setting/plans?${status ? `status=${status}` : ''}&page=${page}&limit=${limit}`,
      }),
      providesTags: ['SiteSetting'],
      //   transformResponse: (response: any) => response.data,
    }),

    getTopups: builder.query({
      query: ({page, limit}) => ({
        url: `${API_URL}/topup?page=${page}&limit=${limit}`,
      }),
      providesTags: ['SiteSetting'],
      // transformResponse: (response: any) => response.data,
    }),
    updateNote: builder.mutation({
      query: ({public_id,note}) => ({
        url:  `${API_URL}/topup/${public_id}`,
        method: 'PATCH',
        body: {note: note},
      }),
      invalidatesTags: ['SiteSetting'],
    }),
  }),
})

export const {
  useGetSettingQuery,
  useGetPlansQuery,
  useGetPlanQuery,
  useUpdateColorMutation,
  useGetColorQuery,
  useGetTopupsQuery,
  useUpdateNoteMutation,
  useResourceUpdateOrCreateMutation,
  useInformationUpdateOrCreateMutation,
  usePlanUpdateOrCreateMutation,
  // useCreateApiKeyMutation
} = settingServiceApi
